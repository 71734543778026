* {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
}

.hidePrint, .hidePrint * {
  visibility: hidden;
  height: 0;
}

h1 {
  visibility: visible;
}

.showPrint {
  visibility: visible;
  height: unset;
}

.showPrint * {
  visibility: visible;
  height: unset;
  padding: unset;
  margin: unset;
}
/*# sourceMappingURL=index.5d89db17.css.map */
