* {
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
}

.hidePrint {
    visibility: hidden;
    height: 0;
}

.hidePrint * {
    visibility: hidden;
    height: 0;
}

h1 {
    visibility: visible;
}

.showPrint {
    visibility: visible;
    height: unset;
}

.showPrint * {
   visibility: visible;
    height: unset;
    padding: unset;
    margin: unset;
}

